<!--事件处理-上下线管理-->
<template>
  <div>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-select
            style="width: 200px;"
            placeholder="处理状态"
            @change="getData"
            v-model="filter.status"
            clearable
            filterable
          >
            <el-option :value="0" label="待审核"/>
            <el-option :value="1" label="已通过"/>
            <el-option :value="2" label="未通过"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="filter.start_time"
            type="date"
            placeholder="提交日期"
            @change="getData">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
        </el-form-item>
      </el-form>
    </el-col>

    <el-table
      :data="tableData"
      :loading="loading"
      size="mini"
      border
      :max-height="clientHeight"
      highlight-current-row
      style="width: 100%">
      <el-table-column
        align="center"
        label="序号"
        type="index"
        width="55">
      </el-table-column>
      <el-table-column
        prop="locale_name"
        show-overflow-tooltip
        label="监测点"
        align="center">
      </el-table-column>
      <el-table-column
        prop="owner_name"
        align="center"
        show-overflow-tooltip
        label="所属单位">
      </el-table-column>
      <el-table-column
        prop="mn"
        align="center"
        show-overflow-tooltip
        label="设备编号">
      </el-table-column>
      <el-table-column
        prop="create_at"
        align="center"
        show-overflow-tooltip
        label="提交时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="user_name"
        align="center"
        label="提交人">
      </el-table-column>
      <el-table-column
        prop="reason"
        align="center"
        show-overflow-tooltip
        label="上下线原因">
      </el-table-column>
      <el-table-column
        prop="status_info"
        align="center"
        label="处理状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status_info==='已通过'">已通过</el-tag>
          <el-tag type="danger" v-else-if="scope.row.status_info==='未通过'">未通过</el-tag>
          <el-tag v-else>{{ scope.row.status_info }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="handle_at"
        align="center"
        show-overflow-tooltip
        label="处理日期">
      </el-table-column>
      <el-table-column
        align="center"
        label="操作">
        <template slot-scope="scope">
          <i
            title="编辑"
            v-if="scope.row.status_info==='待审核'"
            @click="handleEdit(scope.row)"
            class="opt el-icon-edit"
          />
          <i
            title="查看"
            @click="handleView(scope.row)"
            class="opt el-icon-view"
          />
          <i
            title="删除"
            @click="handleDel(scope.row)"
            class="opt el-icon-delete"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="toolbar" style="text-align:center">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </div>

    <offline-view-dialog
      :id="id"
      :visible.sync="viewVisible"
      :mode="viewMode"
      @handleClose="handleViewClose"
    />

  </div>
</template>
<script>
import {mapState} from "vuex";
import {getUserInfo, ownerJoin} from "@/util";
import dayjs from "dayjs";
import OfflineViewDialog from "@/component/offlineManagement/OfflineViewDialog.vue";

export default {
  name: 'OfflineManagement',
  components: {OfflineViewDialog},
  data() {
    return {
      filter: {
        page: 1,
        size: 10,
        status: null,
        owner: [],
        date: null,
        is_download: false,
        start_time: null,
        end_time: null
      },
      customerProps: {
        value: 'Id',
        label: 'Name',
        children: 'children',
        checkStrictly: true
      },
      tableData: [],
      loading: false,
      total: 0,
      viewVisible: false,
      id: null,
      viewMode: null
    }
  },
  computed: {
    ...mapState(['customerTree','clientHeight']),
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    getData() {
      this.loading = true
      let param = Object.assign({}, this.filter)
      if (param.owner.length !== 0) {
        let userInfo = getUserInfo().Info
        param.owner = ownerJoin(param.owner, userInfo)
      }

      if (param.start_time) {
        param.end_time = dayjs(param.start_time).add(1, 'day').unix()
        param.start_time = dayjs(param.start_time).unix()
      }

      this.$get('admin/offline_apply', param).then(res => {
        this.tableData = res.list
        this.total = res.count
        this.loading = false
      })
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      let param = Object.assign({}, this.filter)
      param.size = num
      param.is_download = 1
      if (param.owner.length !== 0) {
        let userInfo = getUserInfo().Info
        param.owner = ownerJoin(param.owner, userInfo)
      }
      if (param.start_time) {
        param.end_time = dayjs(param.start_time).add(1, 'day').unix()
        param.start_time = dayjs(param.start_time).unix()
      }

      this.$get('admin/offline_apply', param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },
    handleEdit(row) {
      this.id = row.id
      this.viewVisible = true
      this.viewMode = 'edit'
    },
    // 查看
    handleView(row) {
      this.id = row.id
      this.viewVisible = true
      this.viewMode = 'view'
    },
    handleViewClose() {
      this.viewVisible = false
      this.getData()
    },
    handleDel(row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
          this.$post('admin/offline_apply/delete/' + row.id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getData()
          })
        })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
